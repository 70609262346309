import { Helmet } from "react-helmet";
import { Route } from "react-router-dom";

const RoutePage = ({ children, path, title = "" }) => {
  return (
    <Route path={path}>
      <Helmet>
        <title>{title}</title>
        <link rel="preconnect" href="https://www.googletagmanager.com" />
        {path && (
          <link rel="canonical" href={`https://dreamocompany.com${path}`} />
        )}
      </Helmet>
      {children}
    </Route>
  );
};

export default RoutePage;
