import { useState } from "react";
import HeroImage from "./assets/Hero.png";
import EcommerceIcon from "./assets/Ecommerce Icon.svg";
import MegaphoneIcon from "./assets/Megaphone Icon.svg";
import AnalyticsIcon from "./assets/Analytics Icon.svg";
import BigDataIcon from "./assets/Big Data Icon.svg";
import AIMLIcon from "./assets/AIML Icon.svg";
import DemoSnippetImage from "./assets/Demo Snippet.svg";
import CardSnippetImage from "./assets/Card Snippet.svg";
import ChartImage from "./assets/Chart.svg";
import EcommerceDashboardImage from "./assets/Ecommerce Dashboard.svg";
import EasternMarketImage from "./assets/Eastern Market Logo.png";
import NoodleKingImage from "./assets/Noodle King Logo.png";
import Demo from "./Demo";

const icons = [
  {
    Icon: EcommerceIcon,
    name: "E-commerce",
    description: "Digitalize, market, and grow your business online.",
  },
  {
    Icon: MegaphoneIcon,
    name: "Marketing",
    description:
      "Build your brand and growth goals through personalized campaigns.",
  },
  {
    Icon: AnalyticsIcon,
    name: "Analytics",
    description: "Real-time dashboards custom-tailored to your insights.",
  },
  {
    Icon: BigDataIcon,
    name: "Big data",
    description: "Monitor millions of key operational data points instantly.",
  },
  {
    Icon: AIMLIcon,
    name: "AI & ML",
    description:
      "Deliver personalized experiences to drive customer retention and purchases.",
  },
];

const Hero = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <section className="relative">
      {isOpen && <Demo isOpen={isOpen} onClose={() => setIsOpen(false)} />}
      <img src={HeroImage} alt="Hero" className="w-full filter brightness-50" />
      <div className="absolute inset-center text-white text-center w-full p-1">
        <h1 className="text-2xl sm:text-3xl md:text-4xl xl:text-6xl">
          One e-commerce platform to do it all
        </h1>
        <button
          onClick={() => setIsOpen(true)}
          className="inline-flex tracking-wide justify-center px-4 py-2 mt-10 text-lg font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
        >
          Get a demo
        </button>
      </div>
    </section>
  );
};

const AtlasPlatform = () => (
  <section className="text-center mt-10">
    <h1 className="font-medium text-2xl md:text-3xl xl:text-4xl">
      Atlas Platform
    </h1>
    <h2 className="text-md sm:text-xl xl:text-2xl mt-5 px-10">
      offers an e-commerce ecosystem to help you manage and scale your business.
    </h2>
    <div className="flex flex-col sm:flex-row flex-wrap mt-8 md:mt-20 justify-evenly items-center px-5 md:px-10 lg:px-15">
      {icons.map((icon) => (
        <div key={icon.name} className="flex-col py-5 w-72">
          <img
            src={icon.Icon}
            alt={`${icon.name} icon`}
            className="inline object-cover h-8 md:h-12"
          />
          <h2 className="text-xl py-2">{icon.name}</h2>
          <h2 className="text-gray-500">{icon.description}</h2>
        </div>
      ))}
    </div>
  </section>
);

const Preview = ({ title, subtitle, img, reverse }) => (
  <div
    className={`flex flex-nowrap px-12 py-12 lg:px-60 xl:px-64 flex-col items-center md:justify-between md:items-center md:${
      reverse ? "flex-row-reverse" : "flex-row"
    }`}
  >
    <div
      className={`flex-col max-w-md text-center pb-10  md:text-left md:${
        reverse ? "pl-12" : "pr-12"
      }`}
    >
      <h1 className="text-2xl font-medium md:text-3xl xl:text-4xl">{title}</h1>
      <h2 className="text-md sm:text-lg xl:text-xl pt-2">{subtitle}</h2>
    </div>
    {img}
  </div>
);

const ProductPreviews = () => (
  <section className="py-12">
    <Preview
      title="Sell items with ease"
      subtitle="Bring your business online and publish products instantly."
      img={
        <img
          src={DemoSnippetImage}
          alt="Demo Snippet"
          className="md:max-w-lg lg:max-w-lg rounded-md shadow-md"
        />
      }
    />
    <Preview
      title="See daily snapshots"
      subtitle="Stay on top of your previous and current day's performance report using our Atlas Dashboard."
      img={
        <div className="relative">
          <img
            src={CardSnippetImage}
            alt="Card Snippet"
            className="absolute w-1/6 -top-2 -left-3 shadow-md rounded-md "
          />
          <img
            src={ChartImage}
            alt="Chart"
            className="absolute w-3/4 top-20 left-28 md:top-24 md:left-32 lg:top-28 lg:left-36 shadow-md rounded-lg"
          />
          <img
            src={EcommerceDashboardImage}
            alt="Ecommerce Dashboard"
            className="md:max-w-lg lg:max-w-lg shadow-md rounded-xl "
          />
        </div>
      }
      reverse
    />
    <Preview
      title="Use AI-driven analytics to improve operations"
      subtitle={
        <div>
          Understand the "why" behind your customers through our AI-driven
          analytics. Atlas AI gives insights into customer’s activities, product
          performances, forecasts, and more.
        </div>
      }
      img={
        <img
          src={ChartImage}
          alt="Chart"
          className="md:max-w-sm lg:max-w-lg rounded-xl shadow-md"
        />
      }
    />
  </section>
);

const Testimonials = () => (
  <section className="items-center text-gray-600 body-font">
    <div className="container px-5 py-24 mx-auto">
      <div className="flex flex-wrap -m-4">
        <div className="p-4 md:w-1/2 w-full">
          <div className="h-full bg-indigo-100 p-8 rounded">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="block w-5 h-5 text-gray-400 mb-4"
              viewBox="0 0 975.036 975.036"
            >
              <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
            </svg>
            <p className="leading-relaxed mb-6">
              With Dreamo's Atlas Platform, we have improved our customer
              acquisition and retention rates leading to a 58% growth in 3
              months. Atlas makes it convenient for us to improve our business
              by creating a feedback loop that continually enhances and
              personalizes our customers' shopping experience.
            </p>
            <div className="inline-flex items-center">
              <img
                src={EasternMarketImage}
                alt="Eastern Market DC"
                className="h-14 w-14 max-w-xs"
              />
              <span className="flex-grow flex flex-col pl-4">
                <span className="title-font font-medium text-gray-900">
                  Eastern Market DC
                </span>
                <span className="text-gray-500 text-sm">
                  Mark Y. (Digital Marketing Lead)
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="p-4 md:w-1/2 w-full">
          <div className="h-full bg-indigo-100 p-8 rounded">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="block w-5 h-5 text-gray-400 mb-4"
              viewBox="0 0 975.036 975.036"
            >
              <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
            </svg>
            <p className="leading-relaxed mb-6">
              We needed a digital and design strategy that was quick and simple.
              Atlas eCommerce provided that out-of-the-box. Atlas revamped our
              website's defining features such as the product recommendation
              strategy, look-and-feel, and inventory management dashboard. By
              streamlining the workflow for our team and customers, we saw an
              overall increase in content engagement, unique clicks, form fill
              conversions, and recommended products purchased.
            </p>
            <div className="inline-flex items-center">
              <img
                src={NoodleKingImage}
                alt="Eastern Market DC"
                className="h-12 w-30 max-w-xs  rounded-full"
              />
              <span className="flex-grow flex flex-col  pl-4">
                <span className="title-font font-medium text-gray-900">
                  Noodle King
                </span>
                <span className="text-gray-500 text-sm">Mia C. (Designer)</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const Home = () => {
  return (
    <div>
      <div className="circle-left" />
      <div className="circle-right" />
      <Hero />
      <AtlasPlatform />
      <ProductPreviews />
      <Testimonials />
    </div>
  );
};

export default Home;
