import { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const useGoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (location) {
      const currentPath = location.pathname + location.search;
      ReactGA.pageview(currentPath);
    }
  }, [location]);
};

export default useGoogleAnalytics;
