import { Helmet } from "react-helmet";
import Footer from "./Footer";
import NavBar from "./NavBar";
import ScrollToTop from "./common/ScrollToTop";
import useGoogleAnalytics from "./utils/useGoogleAnalytics";
import Router from "./Router";

function App() {
  useGoogleAnalytics();

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://dreamocompany.com" />
      </Helmet>
      <ScrollToTop />
      <NavBar />
      <div className="relative overflow-hidden">
        <Router />
        <Footer />
      </div>
    </>
  );
}

export default App;
