import { Switch } from "react-router-dom";
import RoutePage from "./common/RoutePage";
import Home from "./Home";
import Pricing from "./Pricing";
// import Company from "./Company";
import Privacy from "./Privacy";
import Terms from "./Terms";
import Contact from "./Contact";
import Error from "./Error";
import Sitemap from "./Sitemap";

function Router() {
  return (
    <Switch>
      {/* <RoutePage path="/company">
            <Company />
          </RoutePage> */}
      <RoutePage
        path="/pricing"
        title="Pricing - Choose the plan that's best for you | Dreamo"
      >
        <Pricing />
      </RoutePage>
      <RoutePage exact path="/contact" title="Contact Sales | Dreamo">
        <Contact />
      </RoutePage>
      <RoutePage exact path="/privacy" title="Privacy Policy | Dreamo">
        <Privacy />
      </RoutePage>
      <RoutePage exact path="/terms" title="Terms of Service | Dreamo">
        <Terms />
      </RoutePage>
      <RoutePage exact path="/sitemap" title="Sitemap | Dreamo">
        <Sitemap />
      </RoutePage>
      <RoutePage exact path="/">
        <Home />
      </RoutePage>
      <RoutePage>
        <Error />
      </RoutePage>
    </Switch>
  );
}

export default Router;
