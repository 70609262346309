import { Link } from "react-router-dom";

const Error = () => (
  <div className="min-w-screen min-h-screen  flex items-center p-5 lg:p-20 overflow-hidden relative">
    <div className="flex-1 min-h-full min-w-full rounded-3xl bg-white  p-10 lg:p-20 text-gray-800 relative md:flex items-center text-center md:text-left">
      <div className="w-full md:w-1/2 z-10">
        <div className="mb-10 md:mb-20 text-gray-600 font-light">
          <h1 className="font-black uppercase text-2xl lg:text-4xl mb-10">
            404 - Page not found
          </h1>
          <p className="font-medium text-lg">
            The page you're looking for isn't available.
          </p>
        </div>
        <div className="mb-20 md:mb-0">
          <Link
            to="/"
            className="inline-flex flex-1 tracking-wide justify-center px-4 py-2 text-lg font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
          >
            Go home
          </Link>
        </div>
      </div>
    </div>
    <div className="z-0 w-64 md:w-96 h-96 md:h-full bg-blue-200 bg-opacity-30 absolute -top-64 md:-top-96 right-20 md:right-32 rounded-full pointer-events-none -rotate-45 transform"></div>
  </div>
);

export default Error;
