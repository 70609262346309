import React from "react";
import { hydrate, render } from "react-dom";
import ReactGA from "react-ga";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";

if (process.env.REACT_APP_NODE_ENV === "production") {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);
}

const rootElement = document.getElementById("root");
const RootApp = () => (
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENTID}
      redirectUri={window.location.origin}
    >
      <Router>
        <App />
      </Router>
    </Auth0Provider>
  </React.StrictMode>
);

if (rootElement.hasChildNodes()) {
  hydrate(<RootApp />, rootElement);
} else {
  render(<RootApp />, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
