import { useState } from "react";
import { useForm } from "react-hook-form";
import Input from "./common/Input";

const Contact = () => {
  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      await fetch(process.env.REACT_APP_CONTACT_FORM_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      });
    } catch (e) {
      console.log(e);
    } finally {
      setSubmitted(true);
    }
  };

  return (
    <div>
      <div className="max-w-screen-md my-10 px-16 grid gap-8 py-16 mx-auto text-gray-900 rounded-lg shadow-2xl">
        <h2 className="text-4xl lg:text-5xl font-bold leading-tight">
          Contact Sales
        </h2>
        <div>
          {submitted ? (
            <div>
              Thank you! We will be in touch to find a time that works for you.
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Input
                containerClassName="pb-5"
                label="First Name"
                name="firstName"
                placeholder="First Name"
                inputProps={register("firstName", { required: true })}
                errorMessage={
                  errors.firstName?.type === "required" &&
                  "This field is required"
                }
              />
              <Input
                containerClassName="pb-5"
                label="Last Name"
                placeholder="Last Name"
                name="lastName"
                inputProps={register("lastName", { required: true })}
                errorMessage={
                  errors.lastName?.type === "required" &&
                  "This field is required"
                }
              />
              <Input
                containerClassName="pb-5"
                label="Email"
                placeholder="Email"
                name="email"
                inputProps={register("email", {
                  required: true,
                  pattern:
                    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i,
                })}
                errorMessage={
                  (errors.email?.type === "required" ||
                    errors.email?.type === "pattern") &&
                  "Please enter a valid email"
                }
              />
              <Input
                containerClassName="pb-5"
                label="Phone"
                placeholder="Phone"
                name="phone"
                inputProps={register("phone", {
                  required: true,
                  pattern: /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/i,
                })}
                errorMessage={
                  (errors.phone?.type === "required" ||
                    errors.phone?.type === "pattern") &&
                  "Please enter a valid phone number"
                }
              />
              <Input
                containerClassName="pb-5"
                label="Company Name"
                placeholder="Company Name"
                name="companyName"
                inputProps={register("companyName", { required: true })}
                errorMessage={
                  errors.companyName?.type === "required" &&
                  "This field is required"
                }
              />
              <Input
                containerClassName="pb-5"
                label="Message"
                placeholder="Message"
                name="message"
                inputProps={register("message")}
                textarea
              />
              <div className="mt-8">
                <button
                  type="submit"
                  className="text-sm font-bold tracking-wide bg-blue-600 hover:bg-blue-500 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline"
                >
                  Send Message
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contact;
