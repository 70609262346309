import { useForm } from "react-hook-form";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useState } from "react";
import Input from "./common/Input";

const Demo = ({ isOpen, onClose }) => {
  const [submitted, setSubmitted] = useState(false);
  const closeButtonRef = useRef(null);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const submit = async (data) => {
    try {
      await fetch(process.env.REACT_APP_DEMO_FORM_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      });
    } catch (e) {
      console.log(e);
    } finally {
      setSubmitted(true);
    }
  };

  const close = () => {
    onClose();
  };

  return (
    <Transition
      appear
      initialFocus={submitted ? closeButtonRef : undefined}
      show={isOpen}
      as={Fragment}
    >
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={close}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-opacity-90 bg-gray-700 " />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              {submitted ? (
                <>
                  <div>
                    Thank you! We will be in touch to find a time that works for
                    you.
                  </div>
                  <div className="flex mt-3">
                    <button
                      ref={closeButtonRef}
                      onClick={close}
                      className="inline-flex flex-1 tracking-wide justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    >
                      Close
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Schedule your personal demo with us
                  </Dialog.Title>
                  <form className="mt-5" onSubmit={handleSubmit(submit)}>
                    <Input
                      containerClassName="pb-5"
                      label="First Name"
                      name="firstName"
                      placeholder="First Name"
                      inputProps={register("firstName", { required: true })}
                      errorMessage={
                        errors.firstName?.type === "required" &&
                        "This field is required"
                      }
                    />
                    <Input
                      containerClassName="pb-5"
                      label="Last Name"
                      placeholder="Last Name"
                      name="lastName"
                      inputProps={register("lastName", { required: true })}
                      errorMessage={
                        errors.lastName?.type === "required" &&
                        "This field is required"
                      }
                    />
                    <Input
                      containerClassName="pb-5"
                      label="Email"
                      placeholder="Email"
                      name="email"
                      inputProps={register("email", {
                        required: true,
                        pattern:
                          /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i,
                      })}
                      errorMessage={
                        (errors.email?.type === "required" ||
                          errors.email?.type === "pattern") &&
                        "Please enter a valid email"
                      }
                    />
                    <Input
                      containerClassName="pb-5"
                      label="Phone"
                      placeholder="Phone"
                      name="phone"
                      inputProps={register("phone", {
                        required: true,
                        pattern: /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/i,
                      })}
                      errorMessage={
                        (errors.phone?.type === "required" ||
                          errors.phone?.type === "pattern") &&
                        "Please enter a valid phone number"
                      }
                    />
                    <Input
                      containerClassName="pb-5"
                      label="Company Name"
                      placeholder="Company Name"
                      name="companyName"
                      inputProps={register("companyName", { required: true })}
                      errorMessage={
                        errors.companyName?.type === "required" &&
                        "This field is required"
                      }
                    />
                    <div className="flex mt-3">
                      <button
                        type="submit"
                        className="inline-flex flex-1 tracking-wide justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      >
                        Schedule My Demo
                      </button>
                    </div>
                  </form>
                </>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Demo;
