const Privacy = () => (
  <section className="padding-y-lg px-5">
    <header className="mx-auto max-width-xs margin-bottom-lg">
      <div className="text-component text-center line-height-lg v-space-md margin-bottom-md">
        <h1 className="text-4xl text-gray-700 font-bold pt-5 pb-2">
          Privacy Policy
        </h1>
      </div>
    </header>
    <div className="mx-auto max-w-3xl pb-10">
      <div className="text-component line-height-lg v-space-md">
        <p>
          This Privacy Policy describes how your personal information is
          collected, used, and shared when you visit or make a purchase
          from:&nbsp;
          <a
            href="https://www.dreamocompany.com"
            rel="noreferrer"
            target="_blank"
            className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          >
            https://www.dreamocompany.com
          </a>{" "}
          (the "Site").
        </p>
        {/* Consent Section */}
        <h2 className="text-2xl pt-5 text-gray-700 font-bold">Consent</h2>
        <p>
          By using our website, you hereby consent to our Privacy Policy and
          agree to its terms.
        </p>
        {/* Personal Information Section */}
        <h2 className="text-2xl pt-5 text-gray-700 font-bold">
          Personal information we collect
        </h2>
        <p>
          When you visit the Site, we automatically collect certain information
          about your device, including information about your web browser, IP
          address, time zone, and some of the cookies that are installed on your
          device. Additionally, as you browse the Site, we collect information
          about the individual web pages or products that you view, what
          websites or search terms referred you to the Site, and information
          about how you interact with the Site. We refer to this
          automatically-collected information as "Device Information."
        </p>
        <br />
        <p>We collect Device Information using the following technologies:</p>
        <br />
        <ul className="list-inside list-disc">
          <li>
            "Cookies" are data files that are placed on your device or computer
            and often include an anonymous unique identifier. For more
            information about cookies, and how to disable cookies, visit:&nbsp;
            <a
              href="http://www.allaboutcookies.org"
              rel="noreferrer"
              target="_blank"
              className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
            >
              http://www.allaboutcookies.org
            </a>
            .
          </li>
          <li>
            "Log files" track actions occurring on the Site, and collect data
            including your IP address, browser type, Internet service provider,
            referring/exit pages, and date/time stamps.
          </li>
        </ul>
        <br />
        <p>
          Additionally when you make a purchase or attempt to make a purchase
          through the Site, we collect certain information from you, including
          your name, billing address, shipping address, payment information
          (including credit card numbers), email address, and phone number. We
          refer to this information as "Order Information."
        </p>
        <br />
        <p>
          When we talk about "Personal Information" in this Privacy Policy, we
          are talking both about Device Information and Order Information.
        </p>
        <h2 className="text-2xl pt-5 text-gray-700 font-bold">
          How we use your information
        </h2>
        <p>
          We use the Order Information that we collect generally to fulfill any
          orders placed through the Site (including processing your payment
          information and providing you with invoices and/or order
          confirmations). Additionally, we use this Order Information to:
        </p>
        <br />
        <ul className="list-inside list-disc">
          <li>Communicate with you;</li>
          <li>Activate your subscription;</li>
        </ul>
        <br />
        <p>
          We use the Device Information that we collect to help us screen for
          potential risk and fraud (in particular, your IP address), and more
          generally to improve and optimize our Site (for example, by generating
          analytics about how our customers browse and interact with the Site,
          and to assess the success of our marketing and advertising campaigns).
        </p>
        <h2 className="text-2xl pt-5 text-gray-700 font-bold">
          Sharing your personal information
        </h2>
        <p>
          We share your Personal Information with third parties to help us use
          your Personal Information, as described above. We use Google Analytics
          to help us understand how our customers use the Site--you can read
          more about how Google uses your Personal Information here:&nbsp;
          <a
            href="https://www.google.com/intl/en/policies/privacy"
            rel="noreferrer"
            target="_blank"
            className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          >
            https://www.google.com/intl/en/policies/privacy/
          </a>
          . You can also opt-out of Google Analytics here:&nbsp;
          <a
            href="https://tools.google.com/dlpage/gaoptout"
            rel="noreferrer"
            target="_blank"
            className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          >
            https://tools.google.com/dlpage/gaoptout
          </a>
          .
        </p>
        <br />
        <p>
          Finally, we may also share your Personal Information to comply with
          applicable laws and regulations, to respond to a subpoena, search
          warrant or other lawful request for information we receive, or to
          otherwise protect our rights.
        </p>
        <h2 className="text-2xl pt-5 text-gray-700 font-bold">Do Not Track</h2>
        <p>
          Please note that we do not alter our Site’s data collection and use
          practices when we see a Do Not Track signal from your browser.
        </p>
        <h2 className="text-2xl pt-5 text-gray-700 font-bold">Your rights</h2>
        <p>
          If you are a European resident, you have the right to access personal
          information we hold about you and to ask that your personal
          information be corrected, updated, or deleted. If you would like to
          exercise this right, please contact us through the contact information
          below.
        </p>
        <br />
        <p>
          Additionally, if you are a European resident we note that we are
          processing your information in order to fulfill contracts we might
          have with you (for example if you make an order through the Site), or
          otherwise to pursue our legitimate business interests listed above.
          Additionally, please note that your information will be transferred
          outside of Europe, including to Canada and the United States.
        </p>
        <h2 className="text-2xl pt-5 text-gray-700 font-bold">Minors</h2>
        <p>The Site is not intended for individuals under the age of 13.</p>
        <h2 className="text-2xl pt-5 text-gray-700 font-bold">Changes</h2>
        <p>
          We may update this privacy policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal or regulatory reasons.
        </p>
        <h2 className="text-2xl pt-5 text-gray-700 font-bold">Contact us</h2>
        <p>
          For more information about our privacy practices, if you have
          questions, or if you would like to make a complaint, please contact us
          by e-mail at llcdreamo@gmail.com or by our contact page:&nbsp;
          <a
            href="https://dreamocompany.com/contact"
            rel="noreferrer"
            target="_blank"
            className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          >
            https://dreamocompany.com/contact
          </a>
          .
        </p>
      </div>
    </div>
  </section>
);

export default Privacy;
