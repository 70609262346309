import { CheckCircleIcon } from "@heroicons/react/solid";
import cx from "classnames";
import { Link } from "react-router-dom";

const standardFeatures = [
  "Online store",
  "Unlimited products",
  "1 Atlas Analytics report per month",
  "1 Free domain per year",
  "3 collaborator accounts",
  "Email/phone support 24/7",
];

const enterpriseFeatures = [
  "Unlimited collaborator accounts",
  "Unlimited Atlas Analytics reports",
  "Unlimited domains",
  "Social media outlets",
  "Mobile application support",
  "Alert email accounts",
  "Custom reports view",
  "Email/phone support Priority 24/7",
];

const PricingCard = ({
  containerClassName,
  priceTitle,
  plan,
  features,
  buttonText,
  to,
}) => (
  <div
    className={cx(
      "flex flex-col shadow-lg rounded-2xl w-64 text-black dark:bg-gray-800 p-4",
      containerClassName
    )}
  >
    <div className="flex text-blue-400  items-center justify-between">
      <p className="text-xl font-medium mb-4">{plan}</p>
    </div>
    <p className="text-3xl">{priceTitle}</p>
    <ul className="text-sm  w-full mt-6 mb-6 flex-1">
      {features.map((feature) => (
        <li key={feature} className="mb-3 flex items-center ">
          <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
          {feature}
        </li>
      ))}
    </ul>
    <Link
      to={to}
      className="text-center tracking-wide font-medium w-full px-3 py-3 text-sm shadow rounded-lg text-white bg-blue-600 hover:bg-blue-500 "
    >
      {buttonText}
    </Link>
  </div>
);

const Pricing = () => (
  <div className="p-5">
    <div className="flex flex-col items-center">
      <h1 className="text-xl tracking-widest text-blue-400 font-medium">
        PLANS & PRICING
      </h1>
      <h2 className="text-lg text-gray-600 font-medium pt-3">
        Choose the plan that's best for you.
      </h2>
    </div>
    <div className="flex flex-row flex-wrap justify-center">
      <PricingCard
        containerClassName="m-6"
        features={standardFeatures}
        priceTitle="$29 per month"
        plan="Standard"
        buttonText="Choose Standard"
        to="/contact"
      />
      <PricingCard
        containerClassName="m-6"
        features={enterpriseFeatures}
        priceTitle="Get in touch"
        plan="Enterprise"
        buttonText="Contact Us"
        to="/contact"
      />
    </div>
  </div>
);

export default Pricing;
