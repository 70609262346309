import cx from "classnames";

const Input = ({
  containerClassName,
  label,
  name,
  placeholder,
  inputProps = {},
  errorMessage,
  textarea = false,
}) => {
  const hasError = Boolean(errorMessage);
  return (
    <div className={containerClassName}>
      <label
        htmlFor={label}
        className="uppercase block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div
        className={cx(
          "mt-1 relative rounded-md shadow-sm border border-gray-500",
          { "border-red-500": hasError }
        )}
      >
        {textarea ? (
          <textarea
            type="text"
            name={name}
            id={label}
            className={cx(
              "focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 py-2 pl-2 sm:text-sm rounded-md",
              { "bg-red-100": hasError }
            )}
            placeholder={placeholder}
            {...inputProps}
          />
        ) : (
          <input
            type="text"
            name={name}
            id={label}
            className={cx(
              "focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 py-2 pl-2 sm:text-sm rounded-md",
              { "bg-red-100": hasError }
            )}
            placeholder={placeholder}
            {...inputProps}
          />
        )}
      </div>

      {hasError && (
        <span className="text-red-500 text-xs font-bold">{errorMessage}</span>
      )}
    </div>
  );
};

export default Input;
