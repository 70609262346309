import LinkedInLogo from "./assets/LinkedIn Logo.svg";
import { Link } from "react-router-dom";

const links = [
  {
    name: "Privacy",
    to: "/privacy",
  },
  {
    name: "Terms",
    to: "/terms",
  },
  {
    name: "Contact",
    to: "/contact",
  },
  {
    name: "Sitemap",
    to: "/sitemap",
  },
];
const Footer = () => (
  <footer className="bg-gray-800 dark:bg-gray-800 w-full py-8">
    <div className="max-w-screen-xl mx-auto px-4">
      <ul className="max-w-screen-md mx-auto text-lg font-medium flex flex-wrap justify-center">
        {links.map((link) => (
          <li key={link.name} className="my-2">
            <Link
              className="px-5 text-gray-400 hover:text-gray-600 dark:text-gray-300 dark:hover:text-white transition-colors duration-200"
              to={link.to}
            >
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
      <div className="pt-8 flex max-w-xs mx-auto items-center justify-center">
        <Link
          to={{ pathname: "https://www.linkedin.com/company/dreamollc" }}
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-400 hover:text-gray-600 dark:hover:text-white transition-colors duration-200"
        >
          <img src={LinkedInLogo} alt="LinkedIn Logo" />
        </Link>
      </div>
      <div className="text-center text-gray-400 dark:text-gray-200 pt-10 sm:pt-12 font-light flex items-center justify-center">
        {`© Copyright ${new Date().getFullYear()} Dreamo LLC. All Rights Reserved.`}
      </div>
    </div>
  </footer>
);

export default Footer;
