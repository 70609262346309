import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import HeaderLogo from "./assets/Footer Logo.svg";

const navigation = [
  // { name: "Company", to: "/company", current: false },
  { name: "Pricing", to: "/pricing", current: false },
  {
    name: "Get started",
    to: "/pricing",
    style: "text-white tracking-wide bg-blue-600  hover:bg-blue-500",
    current: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const NavBar = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Disclosure as="nav" className="sticky z-50 top-0 bg-gray-800">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-between">
                <Link to="/" className="flex-shrink-0 flex items-center">
                  <img
                    className="block lg:hidden h-8 w-auto"
                    src={HeaderLogo}
                    alt="Dreamo"
                  />
                  <img
                    className="hidden lg:block h-8 w-auto"
                    src={HeaderLogo}
                    alt="Dreamo"
                  />
                </Link>
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4">
                    {navigation.map((link) => (
                      <Link
                        key={link.name}
                        to={link.to}
                        className={classNames(
                          "text-white hover:bg-gray-700 hover:text-white",
                          "px-3 py-2 rounded-md text-sm font-medium",
                          link.style
                        )}
                      >
                        {link.name}
                      </Link>
                    ))}
                    <button
                      className={classNames(
                        "text-white hover:bg-gray-700 hover:text-white",
                        "px-3 py-2 rounded-md text-sm font-medium"
                      )}
                      onClick={loginWithRedirect}
                    >
                      Log in
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.to}
                  onClick={() => {}}
                  className={classNames(
                    "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block px-3 py-2 rounded-md text-base font-medium"
                  )}
                >
                  {item.name}
                </Link>
              ))}
              <button
                onClick={loginWithRedirect}
                className={classNames(
                  "text-gray-300 hover:bg-gray-700 hover:text-white text-left",
                  "block px-3 py-2 rounded-md text-base font-medium w-full"
                )}
              >
                Log in
              </button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default NavBar;
