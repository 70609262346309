import { Link } from "react-router-dom";

const pages = [
  { path: "/", title: "Home" },
  {
    path: "/pricing",
    title: "Pricing",
  },
  { path: "/contact", title: "Contact" },
  { path: "/privacy", title: "Privacy Policy" },
  { path: "/terms", title: "Terms of Service" },
];

const Sitemap = () => {
  return (
    <div className=" mx-auto max-width-xs px-10 py-16">
      <div className="mx-auto max-w-3xl ">
        <div className="text-left text-component line-height-lg v-space-md">
          <h2 className="font-medium text-2xl lg:text-3xl mb-2 text-gray-600 ">
            Pages
          </h2>
          <ul class="mb-12 space-y-2">
            {pages.map((page) => (
              <li key={page.path}>
                <Link
                  className="text-indigo-500 hover:underline"
                  to={page.path}
                >
                  {page.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sitemap;
